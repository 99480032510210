var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $smsSuccess = $('.js-email-signup__sms_success', $emailContainerNode);
        var $contentErrors = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $firstName = $('.js-email-first-name', $emailContainerNode);
        var $termsAndConditions = $('.js-terms-conditions', $emailContainerNode);
        var $emailError = $('.site-email-signup__message__error__email_address', $emailContainerNode);
        var $phoneError = $('.site-email-signup__message__error__mobile_phone', $emailContainerNode);
        var $smsError = $('.js-site-email-signup-message-error-terms', $emailContainerNode);
        var $genericError = $('.site-email-signup__message__error__generic', $emailContainerNode);
        var $emailTermsAndConditions = $('.js-email-terms-conditions', $emailContainerNode);
        var $emailTermsError = $('.js-email-terms-conditions-error', $emailContainerNode);
        var $smsTermsError = $('.js-sms-terms-conditions-error', $emailContainerNode);
        var $emailTermsLabel = $('.js-site-email-signup-terms-checkbox', $emailContainerNode);
        var $smsTermsLabel = $('.js-site-email-signup-smsterms-checkbox', $emailContainerNode);
        var $emailcheckbox = $('.js-email-terms-checkbox', $emailContainerNode);
        var $emailFormSubmitBtn = $('.js-site-email-signup__submit', $emailContainerNode);
        var showEmailPromo = $emailContainerNode.data('show-email-promotion');
        var $smsUnsubDateInput = $('input[name="UNSUB_SMS_DATE"]', $emailContainerNode);
        var $acceptedPrivacyPolicy = $('.js-accepted-privacy-policy', $emailContainerNode);

        // Show terms + conditions for mobile only
        $mobileNumberInput.on('focus', function (e) {
          $termsAndConditions.slideDown(300);
        });

        if (showEmailPromo) {
          $emailInput.on('focus', function () {
            $emailTermsAndConditions.slideDown(300);
          });

          $emailFormSubmitBtn.on('click', function() {
            var gdprOpt = $('input[name="PC_EMAIL_PROMOTIONS"]').is(':checked');

            $emailTermsError.addClass('hidden');
            $emailTermsError.removeClass('error');
            if ($emailInput.val().length > 0 && !gdprOpt) {
              $emailTermsError.removeClass('hidden');
              $emailTermsError.addClass('error');
              $contentErrors.removeClass('hidden');
              generic.focusErrors($('.js-email-signup__error'), $('.js-email-signup'));

              return false;
            }
          });
        }

        $emailForm.once('js-email-signup').submit(function (submitEvt) {
          var params = {};
          var smsPromotionValue = $smsUnsubDateInput.val() ? '' : 0;

          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $smsSuccess.add($smsError).addClass('hidden');
          $emailInput.removeClass('error');
          $mobileNumberInput.removeClass('error');
          $emailError.addClass('hidden');
          $phoneError.addClass('hidden');
          $smsError.addClass('hidden');
          $smsTermsError.addClass('hidden');
          $genericError.addClass('hidden');
          $emailError.removeClass('error');
          $phoneError.removeClass('error');
          $genericError.removeClass('error');
          $smsError.removeClass('error');
          $smsTermsError.removeClass('error');

          // Transform string into array of form elements
          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          } else {
            params.SMS_PROMOTIONS = smsPromotionValue;
          }

          // Checked for checkbox, val for hidden
          if ($acceptedPrivacyPolicy.prop('checked') || $acceptedPrivacyPolicy.val()) {
            params.ACCEPTED_PRIVACY_POLICY = 1;
          }

          if (showEmailPromo) {
            $emailTermsError.addClass('hidden');
            $emailTermsError.removeClass('error');
            $emailTermsLabel.removeClass('error');
            params.PC_EMAIL_PROMOTIONS = $emailcheckbox.prop('checked') ? 1 : 0;
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $(document).trigger('email_signup:success_and_new');
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                $emailInput.val('');
                $firstName.val('');
                $mobileNumberInput.val('');
                $termsAndConditions.slideUp(0);
                if (showEmailPromo) {
                  $emailInput.val('');
                  $emailTermsAndConditions.slideUp(0);
                }
                generic.overlay.launch({
                  height: 'auto',
                  html: $emailSuccess.html()
                });
              }
            },
            onFailure: function (jsonRpcResponse) {
              var genericMessage = false;
              var emailMessage = false;
              var mobileMessage = false;
              var smsMessage = false;
              var errObjs = jsonRpcResponse.getMessages();
              var i;
              var j;
              var myErr;

              for (i = 0; i < errObjs.length; i++) {
                myErr = errObjs[i];

                for (j = 0; j < myErr.tags.length; j++) {
                  if (myErr.tags[j] === 'field.pc_email_address') {
                    emailMessage = true;
                  } else if (myErr.tags[j] === 'field.mobile_phone') {
                    mobileMessage = true;
                  } else if (myErr.tags[j] === 'field.sms_promotions') {
                    smsMessage = true;
                  }
                }
              }
              if (mobileMessage === true && emailMessage === true) {
                genericMessage = true;
              }
              $contentErrors.removeClass('hidden');
              if (genericMessage === false) {
                if (emailMessage === true) {
                  $emailInput.addClass('error');
                  $emailError.removeClass('hidden');
                  $emailError.addClass('error');
                  $genericError.addClass('hidden');
                  $genericError.removeClass('error');
                } else if (mobileMessage === true) {
                  $mobileNumberInput.addClass('error');
                  $phoneError.removeClass('hidden');
                  $phoneError.addClass('error');
                } else if (smsMessage === true) {
                  $termsAndConditions.addClass('error');
                  $smsTermsLabel.addClass('error');
                  $smsTermsError.removeClass('hidden');
                }
              }

              if (genericMessage === true) {
                $('.js-subscribe-signup').addClass('error');
                $emailInput.addClass('error');
                $mobileNumberInput.addClass('error');
                $genericError.removeClass('hidden');
                $genericError.addClass('error');
                for (i = 0; i < errObjs.length; i++) {
                  myErr = errObjs[i];

                  if (showEmailPromo) {
                    if (myErr.key === 'required_dependency.pc_email_address.pc_email_promotions.dependency.email_signup') {
                      $emailTermsLabel.addClass('error');
                      $emailTermsError.removeClass('hidden');
                      $genericError.addClass('hidden');
                      $genericError.removeClass('error');
                      $emailInput.removeClass('error');
                    }
                  }
                }
              }
              generic.focusErrors($('.js-email-signup__error'), $('.js-email-signup'));
            }
          });
        });
      });
    },

    gdprRemInfoMessageBox: function () {
      if ($('.js-info-msg-gdpr').get(0)) {
        $('.js-info-msg-gdpr').remove();
      }
    },

    gdprSetInfoBoxPosition: function (infoLink, $infoMessageBox, $infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt($infoMessageBox.css('max-width').replace('px', ''));

      if (isMobile) {
        $infoMsgArrow.css({
          left: (infoLinkLeft - 10) + 'px'
        });
        infoLinkLeft = 10;
      } else if (($infoMessageBox.outerWidth(true) === infoMsgMaxWidth) && ((infoLinkLeft + infoMsgMaxWidth) > screen.width)) {
        infoLinkLeft = infoLinkLeft - 480;
        $infoMsgArrow.addClass('top-right-arrow');
      }
      $infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      if (Drupal.settings.common.gdpr_compliant) {
        var isMobile = Unison.fetch.now().name === 'small';

        $('body', context).click(function (e) {
          if (e.target.className !== 'js-info-msg-gdpr' && e.target.className !== 'js-info-msg-gdpr__txt') {
            $('.js-info-msg-gdpr').addClass('hidden');
          }
        });
        $('.js-info-link-gdpr', context).live('click', function (e) {
          e.preventDefault();
          var infoLink = $(e.currentTarget);
          var $infoBoxEle;
          var $infoMessageBox;
          var $infoMsgArrow;

          site.emailSignup.gdprRemInfoMessageBox();
          $infoBoxEle = $('#' + infoLink.attr('message-in'));
          $("<div class='info-msg-gdpr js-info-msg-gdpr'><div class='info-msg-gdpr__arrow js-info-msg-gdpr__arrow'></div><div class='info-msg-gdpr__txt js-info-msg-gdpr__txt'></div></div>").appendTo('body');
          $infoMessageBox = $('.js-info-msg-gdpr');
          $infoMsgArrow = $('.js-info-msg-gdpr__arrow');

          $('.js-info-msg-gdpr__txt').html("<div class='info-msg-gdpr__close js-info-msg-gdpr__close'>X</div>" + $infoBoxEle.html());

          $('.js-info-msg-gdpr__close', context).live('click', function (e) {
            e.stopPropagation();
            site.emailSignup.gdprRemInfoMessageBox();
          });

          site.emailSignup.gdprSetInfoBoxPosition(infoLink, $infoMessageBox, $infoMsgArrow, isMobile);
          $(window).on('resize-debounced', site.emailSignup.gdprSetInfoBoxPosition(infoLink, $infoMessageBox, $infoMsgArrow, isMobile));

          $('.js-info-msg-gdpr__txt a').live('mousedown', function (e) {
            // Reload only on left click
            if (e.which === 1) {
              window.location = $(this).attr('href');
            }
          });
        });
      }
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
      if (Drupal.settings.common.gdpr_compliant) {
        site.emailSignup.gdprToolTip(context);
      }
    }
  };
})(jQuery);
